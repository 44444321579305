
import TestHeader from '../components/TestHeader.vue'
import FooterHome from '../components/FooterHome.vue'

import { defineComponent } from "vue";
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default defineComponent({


  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      isCollapsed: 0,
      selectedNumber: null,
      showSelect: false,
      activeTab: 0,


      tabs: [
        {
          value: "one-side",
          label: "Нэг талдаа",
        },
        {
          value: "two-side",
          label: "Хоёр талдаа",
        },
      ],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      options1: [
        {
          value: "Энгийн",
          label: "Энгийн",
        },
        {
          value: "Бизнес",
          label: "Бизнес",
        },
        {
          value: "1-р Зэрэглэл",
          label: "1-р Зэрэглэл",
        },

        {
          value: "Премиум",
          label: "Премиум",
        },

      ],
      val1: "1 том хүн",
      val2: "Энгийн",
      value1: "",
      value2: "",
      date1: "",
      date2: "",
      selectedDate: null,
      showDatePicker: false,
      currentDate: new Date(),
      formInline: {
        user: '',
        region: '',
        date: '',
      },
      form: {
        region: '',
      }
    };


  },
  computed: {
    calculateSlidesPerView() {

      const screenWidth = window.innerWidth;
      if (screenWidth >= 1468) {
        return 4;
      }

      if (screenWidth >= 1180) {
        return 3;
      }
      if (screenWidth >= 912) {
        return 2;
      }
      else {
        return 1;
      }
    },
  },


  components: {
    Swiper,
    SwiperSlide,
    TestHeader,
    FooterHome,

  },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://easygds.s3.ap-southeast-1.amazonaws.com/CMS/embeded-form/app.js';
    script.async = true;
    script.onload = () => {

      // The external script has loaded, and you can use its functions here if needed
      // For example, you might want to initialize or configure it here.
    };


    document.body.appendChild(script);
  },
  methods: {
    disabledDate(time: Date) {
      return time.getTime() > Date.now();
    },

  },


});
