<template>
  <div class="bg-color4">
    <div class="container mx-auto py-10 lg:px-10 xl:px-0">
      <div
        class="grid lg:grid-cols-2  md:grid-cols-2 grid-cols-1 md:px-4 lg:px-0 items-center justify-center border-b-2 border-color5">
        <div class="md:pl-0 pl-4 object-center">
          <img src="../assets/footerlogo.svg" alt="">
        </div>
        <div class="md:flex lg:space-x-32 md:space-x-14  md:pt-0 pt-8 md:pl-0 pl-4">
          <p class="text-white hover:text-bold md:pb-0 pb-2">
            <router-link to="./location" class="menuEffect relative  hover:scale-110 duration-300 inline-block">Салбарын байршил</router-link>
          </p>
          <p class="text-white">
            <router-link to="./Company" class="menuEffect relative  hover:scale-110 duration-300 inline-block">Бидний тухай</router-link>
          </p>
        </div>
        <div>
        </div>
  
        <div class="md:flex lg:space-x-44 md:space-x-28 pt-2 pb-10 md:pl-0 pl-4">
          <p class="text-white md:pb-0 pb-2">
            <router-link to="#"  class="menuEffect relative  hover:scale-110 duration-300 inline-block">Ажлын байр</router-link>
          </p>
          <p class="text-white">
            <a href="tel:1133-3333" class="menuEffect relative  hover:scale-110 duration-300 inline-block">Холбоо барих</a>
          </p>
        </div>
      </div>
  
  
      <div class="grid lg:grid-cols-2  md:grid-cols-2 grid-cols-1 md:px-4 lg:px-0 items-center justify-center pt-2">
        <div class="md:pl-0 pl-4 object-center">
          <p class="text-color5">
            © 1997-2024 | Эйр Транс ХХК
          </p>
        </div>
        <div class="flex lg:space-x-4 md:space-x-4 space-x-4 md:pt-0 pt-8 md:pl-0 pl-4">
          <div>
            <a href="https://www.facebook.com/airtransofficial" target="_blank">
              <img src="../assets/icons_F.svg" class="w-9 h-9" alt="">
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/p/CqkiHzNr5zJ/" target="_blank">
              <img src="../assets/icons_Insta.svg" class="w-9 h-9" alt="">
            </a>
          </div>
          <div>
            <a href="https://t.me/AirtransMN" target="_blank">
              <img src="../assets/icons_telegram.svg" class="w-9 h-9" alt="">
            </a>
          </div>
        </div>
      </div>
      <!-- <div id="fb-root"></div>
      <div id="fb-customer-chat" class="fb-customerchat">
      </div> fb heseg  -->
    </div>
  </div>
  
</template>


<script>


export default {
  // mounted() {
  //   var chatbox = document.getElementById('fb-customer-chat');
  //   chatbox.setAttribute("page_id", "457439627618055");
  //   chatbox.setAttribute("attribution", "biz_inbox");

  //   window.fbAsyncInit = function() {
  //     /* global FB */
  //     FB.init({
  //       xfbml: true,
  //       version: 'v18.0'
  //     });
  //   };

  //   (function(d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));
  // },
};
</script>
<!-- End Facebook Pixel Code -->
